const DomainTable = ({ data }) => {
    let rows = [];
    for (let i = 0; i < data.length; ++i) {
        if (data[i].rankChange > 0) {
            data[i].rankChange = "+" + data[i].rankChange;
        }
        data[i].rank++;
        rows.push(
            <tr>
                <th>{data[i].rank}</th>
                <td>{data[i].rankChange}</td>
                <td>{data[i].domain}</td>
                <td>{data[i].category}</td>
            </tr>
        );
    }
    return (
        <div className="overflow-x-auto">
            <table className="table w-full">
                <thead>
                <tr>
                    <th>Rank</th>
                    <th>Rank change</th>
                    <th>Domain</th>
                    <th>Category</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        </div>
    )
}

export default DomainTable;
import {AttackLayer3, TotalTrafficChart} from "./Charts";
import DomainTable from "./Tables";
import React, { useState, useEffect } from "react";

let totalTraffic = [];
let topDomains = [];
let attackData = [];

function App() {
    const [hasLoaded, setHasLoaded] = useState();

    useEffect(() => {
        if (!hasLoaded) {
            (async () => {
                const [popularDomains, trafficChange, attackLayer3] = await Promise.all([
                    fetch("https://general-assignment.edwardwc.workers.dev/popular-domains"),
                    fetch("https://general-assignment.edwardwc.workers.dev/traffic-change"),
                    fetch("https://general-assignment.edwardwc.workers.dev/attack-layer3")
                ]);

                const domains = await popularDomains.json();
                const traffic = await trafficChange.json();
                const attacks = await attackLayer3.json();

                for (let i = 0; i < domains.rankingEntries.length; ++i) {
                    topDomains.push(domains.rankingEntries[i]);
                }

                for (let i = 0; i < traffic["data"]["total"].length; ++i) {
                    for (let key in traffic["data"]["total"][i]) { // we need to access the key
                        totalTraffic.push({
                            time: Date.parse(key),
                            totalValue: traffic["data"]["total"][i][key],
                            httpValue: traffic["data"]["http"][i][key]
                        });
                    }
                }

                for (let i = 0; i < attacks["data"].length; ++i) {
                    for (let key in attacks["data"][i]) { // we need to access the key
                        attackData.push({
                            time: Date.parse(key),
                            value: attacks["data"][i][key],
                        });
                    }
                }

                setHasLoaded(true);
            })();
        }
    });

    return (
        hasLoaded ? (
            <html data-theme={"luxury"}>
                <div className="hero p-20">
                    <div className="hero-content text-center">
                        <div className="max-w-md">
                            <h1 className="text-5xl font-bold">Cloudflare Radar&copy;</h1>
                            <p>Created by Edward Coristine</p>
                        </div>
                    </div>
                </div>
                <div className={"p-8 opacity-90"}>
                    <div className={"bg-slate-800 rounded-lg min-w-fit p-8 opacity-90"}>
                        <p className={"text-4xl text-center py-4"}>Traffic</p>
                        <TotalTrafficChart chartData={totalTraffic} />
                    </div>
                </div>
                <div className={"p-8 opacity-90"}>
                    <div className={"bg-slate-800 rounded-lg min-w-fit p-8 opacity-90"}>
                        <p className={"text-4xl text-center py-4"}>Layer 3 DDoS</p>
                        <AttackLayer3 chartData={attackData} />
                    </div>
                </div>
                <div className={"p-8 opacity-90"}>
                    <div className='bg-slate-800 rounded-lg p-4'>
                        <p className={"text-center text-2xl p-8"}>Most popular domains</p>
                        <DomainTable data={topDomains} />
                    </div>
                </div>
            </html>
        ) : (
            <p>Loading data...</p>
        )
    )
}

export default App;
import React from 'react';
import moment from 'moment';

import {
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    Line,
    LineChart
} from 'recharts';

const TotalTrafficTooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
        return (
            <div className="custom-tooltip bg-slate-800 p-4">
                <p>{`Time: ${new Date(label)}`}</p>
                <br />
                <p>{`Total traffic: ${Math.round(payload[0].value*100)}% of Max`}</p>
                <p>{`HTTP: ${Math.round(payload[1].value*100)}% of Max`}</p>
            </div>
        );
    }

    return null;
};

export const TotalTrafficChart = ({ chartData }) => (
    <ResponsiveContainer width = '95%' height = {500} >
        <LineChart
            data = {chartData}
            line = {{ stroke: '#eee' }}
        >
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis
                dataKey = 'time'
                domain = {['auto', 'auto']}
                name = 'Time'
                tickFormatter = {(unixTime) => moment(unixTime).format('HH:mm Do')}
                type = 'number'
            />
            <YAxis/>
            <Tooltip content={<TotalTrafficTooltip/>} />
            <Legend />
            <Line dataKey = 'totalValue' stroke = '#00ffff' name = 'Total Cloudflare traffic' strokeWidth={2} dot={false} />
            <Line dataKey = 'httpValue' stroke = '#ff7373' name = 'HTTP traffic only' strokeWidth={2} dot={false} />
        </LineChart>
    </ResponsiveContainer>
)

const AttackLayer3Tooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
        return (
            <div className="custom-tooltip bg-slate-800 p-4">
                <p>{`Time: ${new Date(label)}`}</p>
                <br />
                <p>{`Total traffic: ${Math.round(payload[0].value*100)}% of Max layer 3 DDoS attacks`}</p>
            </div>
        );
    }

    return null;
};

export const AttackLayer3 = ({ chartData }) => (
    <ResponsiveContainer width = '95%' height = {500} >
        <LineChart
            data = {chartData}
            line = {{ stroke: '#eee' }}
        >
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis
                dataKey = 'time'
                domain = {['auto', 'auto']}
                name = 'Time'
                tickFormatter = {(unixTime) => moment(unixTime).format('HH:mm Do')}
                type = 'number'
            />
            <YAxis/>
            <Tooltip content={<AttackLayer3Tooltip/>} />
            <Legend />
            <Line dataKey = 'value' stroke = '#00ffff' name = 'Attack value' strokeWidth={2} dot={false} />
        </LineChart>
    </ResponsiveContainer>
)